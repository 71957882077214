@font-face {
	font-family: 'proximanovacond-black';
	src: url('./fonts/proximanovacond-black.otf');
}

.main-container {
	height: 100vh;
	width: 100vw;
	background-color: #082f50;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width:1024px) {
	.home-outer-container {
		width: 30vw;
		margin-right: 13vw;
		font-size: 11vh;
		color: white;
		font-family: 'proximanovacond-black';
	}

	.home-text-style {
		margin-top: 1vh;
	}

	.img-home-logo {
		margin-top: 13vh;
		height: 8vh;
		width: auto;
	}

	.img-home-option-logo {
		height: 30vh;
		width: auto;
	}

}

@media only screen and (max-width:1024px) {
	.home-outer-container {
		display: flex;
		margin-right: 5vw;
		justify-content: center;
		align-items: center;
		width: 10vw;
		font-size: 6vh;
		color: white;
		font-family: 'proximanovacond-black';
	}

	.home-text-style {
		margin-top: 1vh;
	}

	.img-home-logo {
		margin-top: 13vh;
		height: 6vh;
		width: auto;
	}

	.img-home-option-logo {
		height: 22vh;
		width: auto;
	}

}